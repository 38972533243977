import React from 'react'
import logo from '../../assets/logo.png'
import { FaRegUser } from "react-icons/fa";
import { NavLink } from 'react-router-dom';

const header = () => {
    return (
        <header>
            <div className='main-logo'>
                <img src={logo} alt="Logo" />
            </div>
            <div className='header-main-content'>
                {/* <svg fill="#FFFFFF" height="25" width="25" vi   ewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0z"></path> </g></svg> */}
                <div className="dropdown account-btn-dropdown">
                    <button className="account-btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <FaRegUser /> Ahmed Raza
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><NavLink className="dropdown-item" to="/account">Profile Settings</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/">Logout</NavLink></li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default header