import React from 'react'
import Header from '../layout/Header'
import Sidebar from '../layout/Sidebar'

const Layout = ({ children, style }) => {

    return (
        <div className='patient-theme'>
            <Header />
            <main>
                <div className='sidebar-content'>
                    <Sidebar />
                </div>
                <div className='main-content' style={style}>
                    {children}
                </div>
            </main >
        </div>
    )
}

export default Layout