import React, { useState } from 'react'
import authentication from '../assets/authentication.jpg'
import logo from '../assets/logo.png'
import { NavLink } from 'react-router-dom'
import { FaRegUser } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";

const Login = () => {
    const [passwordToggle, setPasswordToggle] = useState(false)

    return (
        <div className='patient-theme'>
            <div className='authentication-form'>
                <form action="" method="post">
                    <div className='row align-items-center  py-lg-5'>
                        <div className='col-lg-6'>
                            <img src={authentication} className='img-fluid w-75 mx-auto d-lg-block d-none' alt="images" />
                        </div>
                        <div className='col-lg-1 d-lg-block d-none'></div>
                        <div className='col-lg-4'>
                            <div className='p-lg-0 p-3'>
                                <img src={logo} className='img-fluid mt-3 mb-lg-3 bg-blue p-2 px-4 d-block mx-auto' width={200} style={{ borderRadius: 30 }} alt="images" />
                                <h1 className='text-uppercase text-center my-3'>Login</h1>
                                <div className='input-icons'>
                                    <input type="email" className='mb-3' placeholder='Username' />
                                    <FaRegUser />
                                </div>
                                <div className='input-icons'>
                                    <input type={passwordToggle ? 'text' : 'password'} className='mb-3' placeholder='Password' />
                                    <FiLock />
                                    <span className='password-icon' onClick={() => passwordToggle ? setPasswordToggle(false) : setPasswordToggle(true)}>
                                        {
                                            (passwordToggle ? <IoEyeOutline />
                                                : <IoEyeOffOutline />
                                            )
                                        }
                                    </span>
                                </div>
                                <button className='btn theme-btn btn btn-primary px-5 mt-3 w-max d-block btn-lg mx-auto'>Login</button>
                                <span className='mt-4 d-block text-center text-muted small'>Don’t have an account yet? <NavLink to='/signup' className='d-lg-inline d-block'>Sign up for free</NavLink></span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login